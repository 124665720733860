import './Header.css'

const Footer = () => {
    return (
        <footer>
        <p>
          © United House Productions 2022
        </p>
      </footer>
    )
}

export default Footer